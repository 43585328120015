import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import SunBreakListItem from './SunBreakListItem';
import SunBreakListData from '../reducers/SunBreakList.json';

export default class ModulList extends Component {

  renderModul = (modul) => {
    return (
      <SunBreakListItem
        key={modul.title}
        title={modul.title}
        description={modul.description}
      />
    );
  }

  render() {
    return (
      <div className="mainContainer">
        <Header />
        <div style={{marginTop: '70px'}}>
          {SunBreakListData.map(this.renderModul)}
        </div>
      </div>
    );
  }
}
