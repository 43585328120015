import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Slider from './slider/Slider';
import mailIconImg from '../assets/images/whitemail.png';
import phoneIconImg from '../assets/images/whitephone.jpg';
import locationIconImg from '../assets/images/whitelocation.jpg';
import production from "../assets/images/production.jpg";
import wheel from '../assets/images/wheel.png';
import embedded from '../assets/images/embedded.png';
import smart from '../assets/images/smart.png';
import industrial from '../assets/images/industrial.png';
import webdev from '../assets/images/webdev.png';
import mobile from '../assets/images/mobile.png';
import dizgi from '../assets/images/dizgi.png';
import person from '../assets/images/person.png';
import teknoloji from '../assets/images/teknoloji.jpeg'

export default class HomePage extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const url = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.9731523686833!2d28.86952306189604!3d41.06957949927012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab005fd00eedf%3A0x4649746dba046e96!2sArga+Teknoloji+Sanayi+ve+Ticaret+Ltd.+%C5%9Eti.!5e0!3m2!1str!2str!4v1532373753720";
    return (
      <div className="mainContainer">
        <Header />
        <div id="index-content">
          <div className="about"></div>
          <section
            /* style={{
              backgroundImage: "url(" + teknoloji + ")",
              
            }} */
            className="pt-4 homeContent come-in homeImage"
          >
            <div className="container mt-5">
              <div className="homeContent-text">
                <p>
                  2016 yılının Haziran ayında temelleri atılan Arga Teknoloji San. ve Tic. Ltd. Şti, resmi olarak 2017 yılının Aralık ayında iki ortaklı olarak faaliyete geçmiştir.
                  Kuruluşumuz, ismini eski Türkçe’de “zeki, akıllı” anlamına gelen “Arga”dan almaktadır.
                </p>
                <p>
                  Arga Teknoloji’nin misyonu; ismine uygun olarak, değişen, modernleşen ve küreselleşen piyasa koşullarında, Türkiye pazarında dışa bağımlılığı minimum düzeyde tutan, müşteri odaklı ve akıllı çözümler üretmektir. Ürettiği çözümlere yurtdışından da talepler gelmekte, Arga Teknoloji olarak bu taleplere de cevap verilmektedir.
                </p>
                <p>
                  Arga Teknolojinin vizyonu; ürettiği akıllı çözümler sayesinde hedeflediği büyüme oranını da yakalayarak, piyasa içerisinde akılcı ve kalıcı çözümler üreten, insan odaklı, modern bir firma olmaktır.
                </p>
              </div>
            </div>
          </section>
          <div id="services"></div>
          <section className="pt-4 come-in">
            <div className="container mt-5">
              <div className="row align-items-center">
                <div className="col-12">
                  <h1 className="text-center pb-4 mb-5 mt-5 title">Hizmetlerimiz</h1>
                </div>
                <div className="serviceContainer">
                  <div className="row serviceSection">
                    <div className="col-md-4 d-inline-block serviceTextContainer">
                      <img src={wheel} className="rounded-top" alt="electronic" />
                      <h3 className="serviceHeader">Ürün Geliştirme</h3>
                      <p>
                        Arga Teknoloji, müşteri odaklı ve endüstriyel alanda,
                        ihtiyaca uygun akılcıl çözümler geliştirmektedir.
                      </p>
                    </div>
                    <div className="col-md-4 d-inline-block serviceTextContainer">
                      <img src={production} className="rounded-top" alt="desktop" />
                      <h3 className="serviceHeader">Elektronik Tasarım</h3>
                      <p>
                        Arga Teknoloji, elektronik tasarım ve geliştirme sürecinde dışa
                        bağımlılığı minimum düzeyde tutarak, %100 yerli tasarım ve iş gücü
                        ile elektronik tasarım süreçlerini yürütmektedir.
                      </p>
                    </div>
                    <div className="col-md-4 d-inline-block serviceTextContainer">
                      <img src={embedded} className="rounded-top" alt="production" />
                      <h3 className="serviceHeader">Gömülü Yazılım</h3>
                      <p>
                        Arga Teknoloji, gerek tasarımını kendi yaptığı, gerekse
                        hazır elektronik kartlar üzerinde her türlü gömülü
                        yazılımı geliştirme ve test etme süreçlerini
                        yönetebilmektedir.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-md-4 d-inline-block serviceTextContainer">
                      <img src={dizgi} className="rounded-top" alt="production" />
                      <h3 className="serviceHeader">Dizgi ve Üretim</h3>
                      <p>
                        Arga Teknoloji, tasarımını ve geliştirmesini kendi
                        bünyesinde yaptığı her elektronik kartın numune dizgisini
                        ve tasarım test süreçlerini kendi bünyesinde yapmaktadır.
                        Onaylanmış ve seri üretime çıkan ürünlerin dizgileri ise
                        profesyonel firmalara, Arga Teknoloji kontrolünde
                        yaptırılmaktadır.
                      </p>
                    </div>
                    <div className="col-md-4 d-inline-block serviceTextContainer">
                      <img src={smart} className="rounded-top" alt="developing" />
                      <h3 className="serviceHeader">Akıllı Otomasyon</h3>
                      <p>
                        Arga Teknoloji, ev ve iş yerlerine sunduğu akıllı
                          çözümleri ile otomasyon projelerini yürütmektedir.
                          Otomasyon sürecine ek olarak WEB ve mobil yazılımlarla
                          uzaktan izleme ve kontrol süreçleri yönetilmektedir.
                      </p>
                    </div>
                    <div className="col-md-4 d-inline-block serviceTextContainer">
                      <img src={industrial} className="rounded-top" alt="production" />
                      <h3 className="serviceHeader">Endüstriyel Otomasyon</h3>
                      <p>
                        Arga Teknoloji, PLC destekli ve kendi endüstriyel
                          otomasyon çözümleri ile müşterilerinin ihtiyaçlarına
                          yönelik projeler sunmaktadır. Uzaktan izleme ve kontrol
                          süreçleri WEB ve mobil yazılımlarla desteklenmektedir.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-md-4 d-inline-block serviceTextContainer">
                      <img src={webdev} className="rounded-top" alt="developing" />
                      <h3 className="serviceHeader">Web Uygulamalar</h3>
                      <p>
                        Arga Teknoloji, WEB sitesi tasarımı, single page web
                        uygulamaları, server üzerinde çalışan uygulamalar gibi
                        çeşitli çözümleri müşterilerine sunmaktadır.
                      </p>
                    </div>
                    <div className="col-md-4 d-inline-block serviceTextContainer">
                      <img src={mobile} className="rounded-top" alt="production" />
                      <h3 className="serviceHeader">Mobil Uygulamalar</h3>
                      <p>
                        Arga Teknoloji, Android ve iOS platformu üzerinde mobil
                        uygulamalar geliştirmektedir. Bu uygulamalar akıllı ve
                        endüstriyel otomasyon projeleri ile özel müşteri
                        projelerinde süreç destek, izleme ve kontrol amaçlı
                        kullanılmaktadır.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id='projects'></div>
          <section className="pt-4 come-in">
            <div className="container mt-5">
              <div className="col-12">
                <h1 className="text-center pb-4 mb-5 mt-5 title">Projelerimiz</h1>
                <div>
                  <Slider />
                </div>
              </div>
            </div>
          </section>
          <div id="team"></div>
          <section className="pt-4 come-in">
            <div className="container mt-5">
              <div className="row align-items-center">
                <div className="col-12">
                  <h1 className="text-center pb-4 mb-5 mt-5 title">Ekibimiz</h1>
                </div>
                <div className="col-sm-6 col-lg-3 my-2">
                  <div className="glow-panel-alt">
                    <div className="invpic">
                      <img src={person} className="img-fluid rounded-top" alt="resim" />
                    </div>
                    <div className="p-4 m-2">
                      <p className="invname mb-0">
                        <b>Semih Şenol</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 my-2">
                  <div className="glow-panel-alt">
                    <div className="invpic">
                      <img src={person} className="img-fluid rounded-top" alt="resim" />
                    </div>
                    <div className="p-4 m-2">
                      <p className="invname mb-0">
                        <b>Metin Kundakçıoğlu</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 my-2">
                  <div className="glow-panel-alt">
                    <div className="invpic">
                      <img src={person} className="img-fluid rounded-top" alt="resim" />
                    </div>
                    <div className="p-4 m-2">
                      <p className="invname mb-0">
                        <b>Muhammet Göktürk</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id="contact"></div>
          <section style={{ paddingBottom: '50px' }} className="bg-info pt-1 come-in">
            <div className="container mt-5">
              <div className="col-12">
                <h1 className="text-white my-5 py-3 text-center">
                  ARGA Teknoloji İletişim
                </h1>
              </div>
              <div className="map">
                <iframe title="map" className='map' src={url} ></iframe>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <img className="info-icon-item" src={mailIconImg} alt="mailIconImg" />
                <div className="text-white icon-text">info@argateknoloji.com</div>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <img className="info-icon-item" src={locationIconImg} alt="locationIconImg" />
                <div className="text-white icon-text">Arga Teknoloji San. ve Tic. Ltd. Şti Oruçreis Mahallesi Vadi Cad. No: 108 İstanbul Ticaret Sarayı Kat: 7/449 Esenler İstanbul/Türkiye</div>
              </div>
              <div>
                <img className="info-icon-item" src={phoneIconImg} alt="phoneIconImg" />
                <div className="text-white icon-text">+90 212 438 12 72</div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
