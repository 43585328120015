import React from 'react';

const LeftArrow = (props) => {
  return (
    <div className='backArrow' onClick={props.goToPrevSlide}>
      <i class="fas fa-arrow-circle-left" />
    </div>
  );
}

export default LeftArrow