import React, { Component }  from "react";
import { Link }              from "react-router-dom";

import Header                from "../Header";

export default class ProductDeveloping extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div className="mainContainer">
        <Header />
      </div>
    );
  }
}
