import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../../styles/NavBar.css";
import logo from "../../assets/stoper_logo.png";

class NavBar extends Component {
  render() {
    return (
      <div className="sidebar">
        <Link to="/#" className="sidebar-header">
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <Link style={{ background: "#565656" }} to="/#homeSubmenu">
          PANJUR KONTROL
        </Link>
        <Link style={{ background: "#4c4c4d" }} to="/#">
          SENARYO YÖNETIMI
        </Link>
        <Link style={{ background: "#565656" }} to="/#pageSubmenu">
          BILDIRIMLER
        </Link>
        <Link style={{ background: "#4c4c4d" }} to="/#">
          ÇIKIS YAP
        </Link>
      </div>
    );
  }
}

export default NavBar;
