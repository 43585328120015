import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import ModulListItem from './ModulListItem';
import ModulListData from '../reducers/ModulList.json';

export default class ModulList extends Component {

  renderModul = (modul) => {
    return (
      <ModulListItem
        key={modul.title}
        title={modul.title}
        description={modul.description}
      />
    );
  }

  render() {
    return (
      <div className="mainContainer">
        <Header />
        <div style={{marginTop: '70px'}}>
          {ModulListData.map(this.renderModul)}
        </div>
      </div>
    );
  }
}
