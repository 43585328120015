import React, { Component } from "react";
import { connect } from "react-redux";

import NavBar from "../Menu/NavBar";
import "../../styles/BlindControlList.css";
import BlindControl1 from "./BlindControlPanels/BlindControl1";
import BlindControl2 from "./BlindControlPanels/BlindControl2";
import BlindControl3 from "./BlindControlPanels/BlindControl3";
import BlindControl7 from "./BlindControlPanels/BlindControl7";
import { fetchMobileValues } from "../../actions";

let Interval;
class BlindControlList extends Component {
  componentDidMount() {
    const { username, password, MasterID } = this.props;
    clearInterval(Interval);
    Interval = setInterval(() => {
      if (Date.now() - this.props.userClickedTime > 10000) {
        this.props.fetchMobileValues(username, password, MasterID);
      }
    }, 2000);
    this.props.fetchMobileValues(username, password, MasterID);
  }

  renderBlinds = () => {
    return this.props.blinds.data.blinds.map(blind => {
      const { BlindID, BlindType, SpeedEngine, Angle } = blind;
      if (BlindType === "1") {
        return (
          <div key={BlindID}>
            <BlindControl1 speed={SpeedEngine} angle={Angle} />
          </div>
        );
      } else if (BlindType === "2") {
        return (
          <div key={BlindID}>
            <BlindControl2 speed={SpeedEngine} angel={Angle} />
          </div>
        );
      } else if (BlindType === "3") {
        return (
          <div key={BlindID}>
            <BlindControl3
              speed={SpeedEngine}
              angel={Angle}
              position={blind.Position}
            />
          </div>
        );
      } else if (BlindType === "7") {
        return (
          <div key={BlindID}>
            <BlindControl7 speed={SpeedEngine} angel={Angle} />
          </div>
        );
      } else {
        return;
      }
    });
  };

  render() {
    return (
      <div style={{ height: "100vh" }}>
        <NavBar />
        <div className="row blindRow">{this.renderBlinds()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ blinds, auth, clickTime }) => {
  return {
    blinds: blinds,
    username: auth.username,
    password: auth.password,
    MasterID: blinds.data.blinds[0].MasterID,
    userClickedTime: clickTime
  };
};

export default connect(
  mapStateToProps,
  { fetchMobileValues }
)(BlindControlList);
