import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import thunk from "redux-thunk";
import { Link } from "react-router-dom";

import reducers from "./reducers";
import HomePage from "./components/home_page";
import ElevatorPage from "./components/ElevatorList";
import SunBreakPage from "./components/SunBreakList";
import LaserTagPage from "./components/LaserTagList";
import ModulsPage from "./components/ModulList";
import ElectronicDesign from "./components/services/ElectronicDesign";
import ProductDeveloping from "./components/services/ProductDeveloping";
import Production from "./components/services/Production";
import Software from "./components/services/Software";
import EntryPage from "./components/panjur-react/components/EntryPage";
import BlindControlList from "./components/panjur-react/components/BlindControl/BlindControlList";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

ReactDOM.render(
  <Provider store={createStoreWithMiddleware(reducers)}>
    <BrowserRouter>
      <div>
        <Switch>
          <Route path="/products/panjur/panlist" component={BlindControlList} />
          <Route path="/products/panjur" component={EntryPage} />
          <Route path="/products/elevator" component={ElevatorPage} />
          <Route path="/products/sunbreak" component={SunBreakPage} />
          <Route path="/products/moduls" component={ModulsPage} />
          <Route path="/products/lasertag" component={LaserTagPage} />
          <Route
            path="/services/electronicdesign"
            component={ElectronicDesign}
          />
          <Route
            path="/services/productdeveloping"
            component={ProductDeveloping}
          />
          <Route path="/services/production" component={Production} />
          <Route path="/services/software" component={Software} />
          <Route path="/" component={HomePage} />
        </Switch>
      </div>
    </BrowserRouter>
  </Provider>,
  document.querySelector("#root")
);
