import { combineReducers } from "redux";

import AuthReducer from "./panjurReact/authReducer";
import BlindReducer from "./panjurReact/blindReducer";
import ClickTime from "./panjurReact/clicktTime";

export default combineReducers({
  auth: AuthReducer,
  blinds: BlindReducer,
  clickTime: ClickTime
});
