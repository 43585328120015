export default (state = {}, action) => {
  switch (action.type) {
    case "FETCH_BLINDS":
      console.log("fetched", state);
      return { ...state, ...action.payload };
    case "HANDLE_SPEED1_VALUE":
      console.log("fetched", state);
      console.log("action.payload:", action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          blinds: state.data.blinds.map(el =>
            el.BlindType === "1"
              ? { ...state.data.blinds[0], ...action.payload }
              : el
          )
        }
      };
    case "HANDLE_SPEED2_VALUE":
      return {
        ...state,
        data: {
          ...state.data,
          blinds: state.data.blinds.map(el =>
            el.BlindType === "2"
              ? { ...state.data.blinds[2], ...action.payload }
              : el
          )
        }
      };
    // case "HANDLE_SPEED3_VALUE":
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       blinds: state.data.blinds.map(el =>
    //         el.BlindType === "3"
    //           ? { ...state.data.blinds[0], ...action.payload }
    //           : el
    //       )
    //     }
    //   };
    case "HANDLE_SPEED7_VALUE":
      return {
        ...state,
        data: {
          ...state.data,
          blinds: state.data.blinds.map(el =>
            el.BlindType === "7"
              ? { ...state.data.blinds[3], ...action.payload }
              : el
          )
        }
      };
    case "HANDLE_ANGLE1_VALUE":
      console.log("fetched", state);
      console.log("action.payload:", action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          blinds: state.data.blinds.map(el =>
            el.BlindType === "1"
              ? { ...state.data.blinds[0], ...action.payload }
              : el
          )
        }
      };

    case "FETCH_MOBILE_VALUES":
      console.log("fetched from mobile", state);
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
