import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { setSpeed1Value, setAngle1Value, setClickTime } from "../../../actions";
import "../../../styles/Blind.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Sliderr = createSliderWithTooltip(Slider);
let IntervalID = 0;
let IntervalID1 = 0;
class BlindControl1 extends Component {
  onSpeedChange = value => {
    this.refreshClickTime();
    this.props.setSpeed1Value(value, () => {
      clearInterval(IntervalID);
      IntervalID = setInterval(() => {
        const { username, password, blinds } = this.props;
        const {
          MasterID,
          BlindID,
          BlindType,
          Angle,
          SpeedEngine
        } = blinds.data.blinds[0];
        axios.get(
          `http://178.20.225.140:8888/username=${username}&password=${password}&masterid=${MasterID}&event=2,${BlindID},${BlindType},${Angle},${SpeedEngine},180`
        );
        clearInterval(IntervalID);
      }, 1000);
    });
  };

  onAngleChange = value => {
    this.refreshClickTime();
    this.props.setAngle1Value(value, () => {
      clearInterval(IntervalID1);
      IntervalID1 = setInterval(() => {
        const { username, password, blinds } = this.props;
        const {
          MasterID,
          BlindID,
          BlindType,
          Angle,
          SpeedEngine
        } = blinds.data.blinds[0];
        axios.get(
          `http://178.20.225.140:8888/username=${username}&password=${password}&masterid=${MasterID}&event=2,${BlindID},${BlindType},${Angle},${SpeedEngine},180`
        );
        clearInterval(IntervalID1);
      }, 1000);
    });
  };

  refreshClickTime = () => {
    this.props.setClickTime();
  };

  render() {
    console.log("value", this.props.angle);
    console.log("value", this.props.speed);
    return (
      <div className="blind-wrapper">
        <div className="blind-title">Salon</div>
        <div className="title-divider" />
        <div className="slider-title">HIZ</div>
        <div className="slider-container">
          <Sliderr
            min={1}
            max={5}
            onBeforeChange={this.refreshClickTime}
            value={this.props.speed}
            onChange={this.onSpeedChange}
            handleStyle={{ width: "22px", height: "22px", marginTop: "-7px" }}
            trackStyle={{ height: "8px" }}
            railStyle={{ height: "8px" }}
          />
          <div style={{ marginLeft: "15px" }}>{this.props.speed}</div>
        </div>
        <div className="slider-title">ACI</div>
        <div className="slider-container">
          <Sliderr
            min={0}
            max={180}
            onBeforeChange={this.refreshClickTime}
            value={this.props.angle}
            onChange={this.onAngleChange}
            handleStyle={{ width: "22px", height: "22px", marginTop: "-7px" }}
            trackStyle={{ height: "8px" }}
            railStyle={{ height: "8px" }}
          />
          <div style={{ marginLeft: "15px" }}>{this.props.angle}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ blinds, auth }) => {
  return {
    username: auth.username,
    password: auth.password,
    blinds
  };
};

export default connect(
  mapStateToProps,
  { setSpeed1Value, setAngle1Value, setClickTime }
)(BlindControl1);
