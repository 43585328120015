import axios from "axios";

export const userNameChange = text => {
  return {
    type: "USERNAME_CHANGED",
    payload: text
  };
};

export const passwordChange = text => {
  return {
    type: "PASSWORD_CHANGED",
    payload: text
  };
};

export const setSpeed2Value = (value, callback) => dispatch => {
  dispatch({ type: "HANDLE_SPEED2_VALUE", payload: { SpeedEngine: value } });
  callback();
};

export const setSpeed1Value = (value, callback) => dispatch => {
  dispatch({ type: "HANDLE_SPEED1_VALUE", payload: { SpeedEngine: value } });
  callback();
};

export const setAngle1Value = (value, callback) => dispatch => {
  dispatch({ type: "HANDLE_ANGLE1_VALUE", payload: { Angle: value } });
  callback();
};

export const setSpeed3Value = value => {
  return {
    type: "HANDLE_SPEED3_VALUE",
    payload: { SpeedEngine: value }
  };
};

export const setSpeed7Value = value => {
  return {
    type: "HANDLE_SPEED7_VALUE",
    payload: { SpeedEngine: value }
  };
};

export const fetchBlinds = (blindsObj, callback) => dispatch => {
  dispatch({ type: "FETCH_BLINDS", payload: blindsObj });
  callback();
};

export const fetchMobileValues = (
  username,
  password,
  MasterID
) => async dispatch => {
  const response = await axios.get(
    `http://178.20.225.140:8888/username=${username}&password=${password}&event=getalldata&masterid=${MasterID}&json=1`
  );

  dispatch({ type: "FETCH_MOBILE_VALUES", payload: response });
};

export const setClickTime = () => {
  const time = Date.now();
  return {
    type: "SET_CLICK_TIME",
    payload: time
  };
};
