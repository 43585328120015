import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import "../styles/EntryPage.css";
import logo from "../assets/stoper_logo.png";
import { userNameChange, passwordChange, fetchBlinds } from "../actions";

class HomePage extends Component {
  onEnterClick = () => {
    //const baseURL = "http://178.20.225.140:8888";
    const baseURL = "";
    const { password, username, fetchBlinds } = this.props;

    axios
      //.get(`${baseURL}/username=${username}&password=${password}&event=login`)
      .get(`${baseURL}/deneme`)
      .then(obj => {
        console.log("consolefromobj: ", obj);
        let masterIndex = obj.data.indexOf("MasterID:");
        if (masterIndex >= 0) {
          let MasterID = obj.data.substring(masterIndex + 9, obj.data.length);
          axios
            .get(
              `${baseURL}/username=${username}&password=${password}&event=getalldata&masterid=${MasterID}&json=1`
            )
            .then(obj =>
              fetchBlinds(obj, () => {
                this.props.history.push("/products/panjur/panlist");
              })
            );
        }
      });
  };

  render() {
    const { username, password, userNameChange, passwordChange } = this.props;
    return (
      <div id="homePageContainer">
        <div>
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div style={{ marginTop: "50px" }}>
          <input
            onChange={e => userNameChange(e.target.value)}
            value={username}
            placeholder="Kullanıcı Adı"
            className="homeInput"
          />
        </div>
        <div>
          <input
            onChange={e => passwordChange(e.target.value)}
            value={password}
            placeholder="Şifre"
            className="homeInput"
          />
        </div>
        <div className="buttonContainer">
          <div>
            <label style={{ fontSize: "13px" }}>
              <input
                style={{ marginRight: "3px" }}
                type="checkbox"
                className="checkInput"
              />
              Beni Hatırla
            </label>
          </div>
          <div
            onClick={this.onEnterClick}
            className="btn btn-sm btn-outline-info"
          >
            Giriş Yap
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    username: auth.username,
    password: auth.password
  };
};

export default connect(
  mapStateToProps,
  { userNameChange, passwordChange, fetchBlinds }
)(HomePage);
