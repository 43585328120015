import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { setSpeed7Value } from "../../../actions";
import "../../../styles/Blind.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Sliderr = createSliderWithTooltip(Slider);

class BlindControl7 extends Component {
  onSpeedChange = value => {
    this.props.setSpeed7Value(value);
  };

  render() {
    return (
      <div className="blind-wrapper">
        <div className="blind-title">Çocuk Oda</div>
        <div className="title-divider" />
        <div>HIZ</div>
        <div className="slider-container">
          <Sliderr value={this.props.speed} onChange={this.onSpeedChange} />
        </div>
        <div>ACI</div>
        <div className="slider-container">
          <Sliderr value={this.props.angel} onChange={this.onAngelChange} />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { setSpeed7Value }
)(BlindControl7);
