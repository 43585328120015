import React, { Component } from 'react';

import Slide from './Slide';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';

export default class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: [
        'Asansör piyasasında “Kramer” kapı olarak bilinen, yelpaze tip asansör iç koruma kapısının mekanik üretimini yapan “Stoper Endüstriyel Ürünler” firmasına bu kapının kontrol kartını ve yazılımını sağlamaktadır.',
        'Hareketli güneş kırıcı sistemlerinin mekanik üretimini yapan “Stoper Endüstriyel Ürünler” firmasına bu sistemin kontrol kartlarını ve yazılımlarını sağlamaktadır.',
        'Trafik direk aydınlatması piyasasında ismini duyurmuş “Ekaldes” firmasına “RYGW Direk Led Aydınlatma Kartları”nı ve yazılımlarını sağlamaktadır.',
        'Yine Ekaldes firması için direk aydınlatma kartlarının geliştirilmiş “Uzaktan Erişimli ve Kontrollü, Astronomik Röle Yazılımlı Led Aydınlatma Kartları” yapılmış fakat proje henüz hayata geçirilmemiştir.',
        'Funtech Teknoloji Ürünleri İnşaat San. ve Tic. Ltd. Şti. için “LazerTag” ve “Eğlence” sistemlerinin elektronik kontrol kartları ve yazılımları yine firmamız tarafından desteklenmektedir. (Referans proje: Anka Park LaserTag sahası, ANKARA)'
      ],
      currentIndex: 0,
      translateValue: 0
    }
  }

  goToNextSlide = () => {
    // Exiting the method early if we are at the end of the images array.
    // We also want to reset currentIndex and translateValue, so we return
    // to the first image in the array.
    if (this.state.currentIndex === this.state.projects.length - 1) {
      return this.setState({
        currentIndex: 0,
        translateValue: 0
      })
    }

    // This will not run if we met the if condition above
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      translateValue: prevState.translateValue + -(this.slideWidth())
    }));
  }

  slideWidth = () => {
    return document.querySelector('.slide').clientWidth
  }

  goToPrevSlide = () => {
    if (this.state.currentIndex === 0) {
      return this.setState({
        currentIndex: 4,
        translateValue: -3000
      }) 
    }

    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1,
      translateValue: prevState.translateValue + +(this.slideWidth())
    }));
  }

  render() {
    return (
      <div className='slider'>
        <div className="slider-wrapper"
          style={{
            transform: `translateX(${this.state.translateValue}px)`,
            transition: 'transform ease-out 0.45s'
          }}>
          {
            this.state.projects.map((project, i) => (
              <Slide key={i} project={project} />
            ))
          }
        </div>
        <div>
          <LeftArrow
            goToPrevSlide={this.goToPrevSlide}
          />
          <RightArrow
            goToNextSlide={this.goToNextSlide}
          />
        </div>
      </div>
    );
  }
}