import React, { Component } from "react";
import { Link } from "react-router-dom";

import ytylogo from "../assets/images/yty-logo.png";

export default class Header extends Component {
  render() {
    return (
      <nav className="bg-white fixed-top navbar navbar-expand-lg navbar-light">
        <Link
          to="/"
          className="navbar-brand pl-3 font-weight-bold"
          href="index.html"
        >
          <img src={ytylogo} alt="logom" className="yty_logo" />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a
                className="btn btn-teal btn-round nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Ürünlerimiz
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbarDropdown"
              >
                <Link
                  to="/products/elevator"
                  className="dropdown-item"
                  href="#"
                >
                  Asansör Grubu
                </Link>
                <Link
                  to="/products/sunbreak"
                  className="dropdown-item"
                  href="#"
                >
                  Güneş Kırıcı(Panjur) Grubu
                </Link>
                <Link to="/products/moduls" className="dropdown-item" href="#">
                  Modüller
                </Link>
                <Link
                  to="/products/lasertag"
                  className="dropdown-item"
                  href="#"
                >
                  Lasertag Grubu
                </Link>
                <Link to="/products/panjur" className="dropdown-item" href="#">
                  Akıllı Panjur
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
