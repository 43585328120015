import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import LaserTagListItem from './LaserTagListItem';
import LaserTagListData from '../reducers/LaserTagList.json';

export default class LaserTagList extends Component {

  renderModul = (modul) => {
    return (
      <LaserTagListItem
        key={modul.title}
        title={modul.title}
        description={modul.description}
      />
    );
  }

  render() {
    return (
      <div className="mainContainer">
        <Header />
        <div style={{marginTop: '70px'}}>
          {LaserTagListData.map(this.renderModul)}
        </div>
      </div>
    );
  }
}
