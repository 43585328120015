import React, { Component } from 'react';

import resimYok from '../assets/images/resim-yok.png';

export default class SunBreakListItem extends Component {
  render() {
    return (
      <div className='d-flex glow-panel-alt'>
        <div style={{width: '150px', height: '150px'}} className="invpic">
          <img src={resimYok} className="img-fluid rounded-top" alt="electronic" />
        </div>
        <div className='d-flex flex-column justify-content-center'>
          <div>{this.props.title}</div>
          <div>{this.props.description}</div>
        </div>
      </div>
    );
  }
}