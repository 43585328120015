import React from 'react';

const Slide = ({ project }) => {
  /* const styles = {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 60%'
    height: '100px',
    width: '100px'
  } */
  return (
    <div className="slide">
      {project}
    </div>
  );
}

export default Slide